
import GuiH1 from '@/components/gui/H1.vue';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import AffaireRank from '@/class/AffaireRank';
import ButtonVueSelector from '@/components/gui/ButtonVueSelector.vue';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import PlanificateurEventBus from '@/bus/planificateurEventBus';
import DetailTask from '@/components/planificateur/DetailTask.vue';
import SearchEngine from '@/components/searchAffaire/Engine.vue';
import PlanificateurChangeView from '@/bus/planificateurChangeView';
import UserAuthManager from '@/class/UserAuthManager';
import TotalizerTime from '@/components/planificateur/TotalizerTime.vue';
import AffaireStore from '@/store/AffaireStore';
import CostCenterStore from '@/store/CostCenterStore';
import UserAuthStore from '@/store/UserAuthStore';
import Notiflix from 'notiflix';
import UserStore from '@/store/UserStore';
import PlanificateurRow from '@/components/planificateur/PlanificateurRow.vue';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import routingWeekPlaningEvent from '@/bus/routingWeekPanningEvent';
import PlanningWindowDetails from '@/components/planningWindowDetail/PlanningWindowDetails.vue';
import GanttIndex from '@/components/gantt-shared/GanttIndex.vue';
import OperateurVue from '@/views/Operateur.vue';
import DashboardView from '@/components/dashboard/Dashboard.vue';
import WhatsNew from '@/whats-new';
import WhatsNewPage from '@/components/whats-new/WhatsNewPage.vue';
import FilterPlannificateur from '@/components/filters/FiltresPlannificateur.vue';
import Notify from '@/helper/Notification';
import PlanificateurJobBus from '@/bus/planificateurJobBus';
import loadNewAffaireBus from '@/bus/loadNewAffaireBus';
import loadPercentageAffairesBus from '@/bus/loadPercentageAffairesBus';
import GanttStore from '@/store/GanttStore';
import HebdoPlanning from '@/components/hebdo/HebdoPlanning.vue';

export default defineComponent({
  name: 'CoordinateurView',
  components: {
    DashboardView,
    OperateurVue,
    TotalizerTime,
    SearchEngine,
    DetailTask,
    DraggableWindow,
    ButtonVueSelector,
    GuiH1,
    PlanificateurRow,
    GanttIndex,
    PlanningWindowDetails,
    WhatsNewPage,
    FilterPlannificateur,
    HebdoPlanning,
  },
  data() {
    return {
      vueSelected: 0,
      windowTaskDetails: new Array<AffaireRank>(),
      windowRWPDetails: new Array<RoutingWeekPlanning>(),
      loadPercentageAffaires: 0 as number,
      affaireStore: AffaireStore(),
    };
  },
  computed: {
    affaires: mapState(AffaireStore, ['affaires']).affaires,
    affairesSearch: mapState(AffaireStore, ['affairesSearch']).affairesSearch,
    inputFilter: mapState(AffaireStore, ['inputFilter']).inputFilter,
    ...mapState(UserAuthStore, ['user']),
  },
  methods: {
    setPercentageAffaire(loadPorcentages: number) {
      this.loadPercentageAffaires = (loadPorcentages <= 100 ? loadPorcentages : 100);
    },
    updateLastLoadAffaire() {
      loadNewAffaireBus.trigger('loadNew', true);
      if (this.loadPercentageAffaires === 100) {
        Notiflix.Notify.success('Tout les affaires ont été chargées avec succès');
      } else {
        Notiflix.Notify.warning(`${this.loadPercentageAffaires}% des affaires on etais chargeés`);
      }
    },
    closePRW(rwp: number | RoutingWeekPlanning) {
      let internalId = 0 as number | undefined;
      if (rwp instanceof RoutingWeekPlanning) {
        internalId = rwp.internalId;
      } else {
        internalId = rwp;
      }
      this.windowRWPDetails = this.windowRWPDetails
        .filter((rwpf) => rwpf.internalId !== internalId);
    },
    showDetailsWeekPlanning(rwp: RoutingWeekPlanning) {
      const find = this.windowRWPDetails
        .find((rpwe) => rpwe.internalId === rwp.internalId) as RoutingWeekPlanning | undefined;
      if (find !== undefined) return;
      this.windowRWPDetails.push(rwp);
    },
    showDetailsTask(affaireRank: AffaireRank) {
      const find = this.windowTaskDetails
        .find((rank) => rank.IdJobFile === affaireRank.IdJobFile) as AffaireRank | undefined;
      if (find !== undefined) {
        this.closeDetailsTask(affaireRank);
        return;
      }
      this.windowTaskDetails.push(affaireRank);
    },
    closeDetailsTask(affaireRank: AffaireRank) {
      this.windowTaskDetails = this.windowTaskDetails
        .filter((rank) => rank.IdJobFile !== affaireRank.IdJobFile);
    },
    closeAllDetailsTask() {
      this.windowTaskDetails = [];
    },
    changeView(vueNumber: number) {
      if (vueNumber === 1) {
        Notiflix.Loading.arrows('Chargement de la vue', { svgColor: 'white' });
      }
      setTimeout(() => {
        this.vueSelected = vueNumber;
        Notiflix.Loading.remove();
      });
    },
    logout() {
      UserAuthManager.logout(true);
    },
    loadJobById(id: string) {
      if (this.affaires.filter((a) => a.Job === parseInt(id, 10)).length > 0
        || this.affairesSearch.filter((a) => a.Job === parseInt(id, 10)).length > 0
      ) {
        Notify.notifyInfo('Cette affaire est déjà dans la liste des affaires affichées');
        return;
      }
      Notiflix.Loading.hourglass('Chargement de l\'affaire...', { svgColor: 'rgb(99 102 241)' });
      AffaireStore().loadJob(id, () => {
        Notiflix.Loading.remove();
      });
    },
    loadAllAffaires() {
      // Reset affaires pour ne pas avoir de doublons
      this.affaireStore.removeAllAffaire();
      if (process.env.VUE_APP_ENV === 'PREPROD' || process.env.VUE_APP_ENV === 'DEV') {
        WhatsNew(() => {
          if (this.affaireStore.affaires.length === 0) {
            Notiflix.Confirm.show(
              'Charger les affaires en cours ?',
              'Souhaitez-vous récuperer la liste de toutes les affaires en cours ?',
              'Oui',
              'Non',
              () => {
                AffaireStore()
                  .getAllJobInProgress();
              },
            );
          }
        });
      } else {
        Notiflix.Confirm.show(
          'Charger les affaires en cours ?',
          'Souhaitez-vous récuperer la liste de toutes les affaires en cours ?',
          'Oui',
          'Non',
          () => {
            AffaireStore()
              .getAllJobInProgress();
          },
        );
      }
    },
    RemoveAllFilter() {
      GanttStore().removeAllFilter();
      AffaireStore().resetFilter();
    },
  },
  mounted() {
    loadPercentageAffairesBus.on('loadingPercentage', this.setPercentageAffaire);
    PlanificateurEventBus.on('showDetail', this.showDetailsTask);
    PlanificateurJobBus.on('loadJob', this.loadJobById);
    PlanificateurChangeView.on('changeView', this.changeView);
    routingWeekPlaningEvent.on('showDetail', this.showDetailsWeekPlanning);
    routingWeekPlaningEvent.on('closeDetail', this.closePRW);
    this.loadAllAffaires();
  },
  unmounted() {
    PlanificateurEventBus.off('showDetail', this.showDetailsTask);
    PlanificateurJobBus.off('loadJob', this.loadJobById);
    PlanificateurChangeView.off('changeView', this.changeView);
    routingWeekPlaningEvent.off('showDetail', this.showDetailsWeekPlanning);
    routingWeekPlaningEvent.off('closeDetail', this.closePRW);
  },
  created() {
    CostCenterStore()
      .loadCostCenters();
    UserStore()
      .loadAllUsers();
  },

});
